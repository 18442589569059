import React, { useEffect }  from "react";
import "./howtoWatchScreen.css";

const HowToWatchScreen=()=>{
   const PdfOpener=()=> {
        const openPdf = () => {  
          const pdfURL = "../../assets/documents/KICCTV_Coverage_By_Satellite.pdf";
          window.open(pdfURL, "_blank");
        }
    }

  return(
    
    <div className="watchscreen">
        <div className="wrapper">
            <div className="watchscreenContainer">
                <div className="watchContainer">
                    <h1>How To Watch</h1>
                    <div className="descriptionCard">
                            <div className="imageContainer skylogo">
                                <img src={require("../../assets/images/sky logo.png")} alt="Icon" /> 
                            </div>
                            <div className="infoContainer">
                            <p>Tune into KICCTV on SKY TV - channel 589</p>
                            </div>
                    </div>
                    <div className="descriptionCard">
                            <div className="imageContainer">
                                <img src={require("../../assets/images/satellite.png")} alt="Icon" /> 
                            </div>
                            <div className="infoContainer" onClick={PdfOpener}>
                                <p>Free - To - Air</p>
                                <p>Tune your satellite box to the frequency settings based on your country.</p>
                                <p> Click <a href={require("../../assets/documents/Coverage_By_Satellite.pdf")} target="_blank">here</a> to view the frequencies we are on.</p>
                            </div>
                    </div>
                    <div className="descriptionCard">
                            <div className="imageContainer">
                                <img  src={require("../../assets/images/Samsung_LG.png")}/> 
                            </div>
                            <div className="infoContainer">
                                <p>KICCTV is now available as an app on certain models of LG Smart TV’s and Samsung Smart TV’s. If your compatible Smart TV is connected to the Internet you can browse the app store on your TV and download the KICCTV app.</p>
                            </div>
                    </div>
                    <div className="descriptionCard ">
                            <div className="imageContainer ">
                                <img src={require("../../assets/images/chrome.png")} alt="Icon" /> 
                            </div>
                            <div className="infoContainer">
                                <p>KICCTV can be viewed on Chromecast using the KICCTV App and selecting the Chromecast icon. You may need to install the Google Home App from Google Play Store to set up your Chromecast device.</p>
                            </div>
                    </div>
                </div>
                <div className="iconButtonContainer">
                <h1>Download the App</h1>
                <a >
                    Click below to visit App store
               </a>
                <div className="appstoreContainer">
                    <div className="logoContainer playstorecontainer" title="Android" onClick={()=>window.open(" https://play.google.com/store/apps/details?id=com.kicctv","_blank")}>
                        <img src={require("../../assets/images/googleplay.png")} alt="Logo" />
                    </div>
                    <div className="logoContainer " title="Fire TV" onClick={()=>window.open("https://www.amazon.com/Broadcast-Systems-Europe-Ltd-KICCTV/dp/B09Z6Z37N3","_blank")} >
                        <img src={require("../../assets/images/fire_tv.png")} alt="Logo" />
                    </div>
                    <div className="logoContainer " title="Roku TV"  onClick={()=>window.open("https://channelstore.roku.com/en-ca/details/d0b176ab37526cb97a516f1fce3d114d/kicctv","_blank")}  >
                        <img src={require("../../assets/images/roku.png")} alt="Logo"/>
                    </div>
                    <div className="logoContainer " title="IOS" onClick={()=>window.open("https://apps.apple.com/lb/app/kicctv-live/id1603445865","_blank")}>
                        <img src={require("../../assets/images/ios.png")} alt="Logo" />
                    </div>
                    <div className="logoContainer " title="Apple Tv" onClick={()=>window.open("https://apps.apple.com/lb/app/kicctv-live/id1603445865?platform=appleTV","_blank")}>                                                                       
                        <img src={require("../../assets/images/apple_tv.png")} alt="Logo" />
                    </div>
                    <div className="logoContainer  googletv" title="Google Tv" onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.kicctv","_blank")}>                                                                       
                        <img src={require("../../assets/images/google tv logo.png")} alt="Logo" />
                    </div>
                </div>      
                </div>
            </div>
           
        </div>

    </div>
   );

}
export default HowToWatchScreen;