import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "./Player.css";
import { service } from "../../Network/Home/Service";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import VideoJS from "../../components/Videojs/VideoJS";
import { ReactComponent as Closeicon } from "../../assets/images/close.svg";
import { convertAdUrl } from '../../Utils/utils';
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import Spinner from "../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useScrollPosition from "../../components/ScrollPosition/useScrollPosition";

let videoPlaying = false;
function Player() {
  const [showDetails, setShowdetails] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ShareButton, setShareButton] = useState(false);
  const playerRef = React.useRef(null);
  const [continueWatching, setContinueWatching] = useState();
  const [watched_duration, setWatchedDuration] = useState();
  var videoStarted = false;
  const [videoDetails, setVideoDetails] = useState(null);
  const [categoryNames, setCategoryNames] = useState();
  let seconds = 60;
  let debounce = true;
  let prevTime;

  const [VideoPlayer, setVideoPlayer] = useState();
  const [VideoName, setVideoName] = useState(``);
  const [videoDescription, setVideoDescription] = useState(``);
  const [playVideoId, setplayVideoId] = useState(searchParams.get("video_ID"));
  const location = useLocation();
  const navigate = useNavigate();
  const [otherEpisodes, setOtherEpisodes] = useState([]);
  var details = [];
  const [newUrl, setNewUrl] = useState(``);
  const [ShowID, setShowID] = useState();
  const [toMyList, settoMyList] = useState(0);
  const [pipMode, setPipMode] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const position = useScrollPosition();

  // share refs
  let shareIconRef = useRef(null);
  let shareDivRef = useRef(null);

  var settings = {
    dots: false,
    infinite: false,
    loop: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: false,
          loop: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
          loop: false,
          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          loop: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: false,
          loop: false,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (position === 0) {
      setPipMode(true);
    }
  }, [position]);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareDivRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareDivRef]);

  useEffect(() => {
    let cat = "";
    if (showDetails) {
      showDetails?.categories?.map((item, index) => {
        cat = cat + item.category_name + ",";
      });
      setCategoryNames(cat);
    }
  }, [showDetails]);
  useEffect(() => {
    if (newUrl) {
      var adUrl = convertAdUrl(videoDetails, showDetails)
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${newUrl}`,
            type: "application/x-mpegURL",
          },
        ],
      };
      setVideoPlayer(
        <VideoJS
          options={videoJsOptions}
          hoverThumbnail={videoDetails?.hover_thumbnail}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
        />
      );
    }
  }, [newUrl]);

  useEffect(() => {
    service
      .getVideoDetails(searchParams.get("video_ID"))
      .then((response) => {
        setVideoDetails(response?.data);
        settoMyList(response?.data?.watchlist_flag);
        setVideoName(response?.data?.video_title);
        details = response?.data;
        let arr = response?.data?.video_name.split("/").reverse();
        setShowID(response?.data?.show_id);
        service.playerToken().then((tokenResponse) => {
          if (localStorage.getItem("isLoggedIn") && details?.watched_duration > 0) {
            if (
              window.confirm("Do you want to watch where you stopped?") == true
            ) {
              setContinueWatching("ok");
            } else {
              setContinueWatching("cancel");
            }
            setWatchedDuration(details?.watched_duration);
          }
          let newUrl =
            "https://poppo.tv/playlist/playlist.m3u8?id=" +
            arr[1] +
            "&token=" +
            tokenResponse.data.data +
            "&type=video" +
            "&pubid=" +
            process.env.REACT_APP_PUBID;
          setNewUrl(newUrl);
        });

        service.getShowDetails(response.data.show_id).then((response) => {
          setShowdetails(response?.data);
          setOtherEpisodes(response.data.videos[0].videos);
        });
      });
  }, []);

  const episodeClick = (id) => {
    console.log("id from ep click", id);
    setplayVideoId(id)
    videoPlaying = false
    service.getVideoDetails(id).then((response) => {
      setVideoName(response?.data?.video_title);
      details = response.data;
      let arr = response.data.video_name.split("/").reverse();
      service.playerToken().then((tokenResponse) => {
        let newUrl =
          "https://poppo.tv/playlist/playlist.m3u8?id=" +
          arr[1] +
          "&token=" +
          tokenResponse.data.data +
          "&type=video" +
          "&pubid=" +
          process.env.REACT_APP_PUBID;
        setNewUrl(newUrl);
      });

      service.getShowDetails(response.data.show_id).then((response) => {
        setOtherEpisodes(response.data.videos[0].videos);
      });
    });
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    window.adPlaybackStarted = false;

    player.on("loadedmetadata", () => {
      if (continueWatching === "ok") {
        player.currentTime(watched_duration.toString());
        !window.adPlaybackStarted && player.play();
      } else {
        !window.adPlaybackStarted && player.play();
      }
    });

    player.on("play", () => {
      let event = videoStarted ? "POP09" : "POP02";
      videoStarted = true;
      service
        .onVideoPlayFunction(
          videoDetails,
          event,
          categoryNames,
          player.currentTime()
        )
        .then((response) => {
          // console.log(response, "video analytics");
        });
    });

    player.on("timeupdate", function (e) {
      let event = "POP03";
      videoPlaying = true;
      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        service
          .onVideoPlayFunction(
            videoDetails,
            event,
            categoryNames,
            player.currentTime()
          )
          .then((response) => {
            // console.log(response, "video timupdate analytics");
          });
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      if (player.duration() !== player.currentTime()) {
        let event = "POP04";
        service
          .onVideoPlayFunction(
            videoDetails,
            event,
            categoryNames,
            player.currentTime()
          )
          .then((response) => {
            // console.log(response, "video PAUSE analytics");
          });
      }
    });

    player.on("ended", () => {
      let event = "POP05";
      service
        .onVideoPlayFunction(
          videoDetails,
          event,
          categoryNames,
          player.currentTime()
        )
        .then((response) => {
          // console.log(response, "video end analytics");
        });
    });

    // Listen for ad events
    player.on("ads-ad-started", () => {
      // console.log('ad started');
      // Pause main video playback when an ad starts
      try {
        player.pause();
      } catch (error) {
        console.log('player pause error'.error);
      }
      window.adPlaybackStarted = true;
    });

    player.on("ads-ad-ended", () => {
      // Resume main video playback when an ad ends
      if (window.adPlaybackStarted) {
        window.adPlaybackStarted = false;
        !window.adPlaybackStarted && player.play();

      }
    });
  };


  const addToMyList = () => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    let id = videoDetails.video_id;
    if (isLoggedIn && userId) {
      service.episodeAddToMyPlayList(id, 1).then((response) => {
        if (response) {
          settoMyList(1);
        }
      });
    } else {
      navigate(`/login`);
    }
  };
  const removeFromMyList = () => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    let id = videoDetails.video_id;
    if (isLoggedIn && userId) {
      service.episodeAddToMyPlayList(id, 0).then((response) => {
        if (response) {
          settoMyList(0);
        }
      });
    } else {
      navigate(`/login`);
    }
  };

  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true);
  });

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false);
  });

  return videoDetails ? (
    <div className="player">
      <div className="wrapper">
        <ToastContainer />
        {position > 400 &&
          pipMode === true &&
          videoPlaying === true &&
          browserPipMode === false && (
            <div className="thumbnailContainer">
              {/* <img
                className="bg"
                src={require("../../assets/images/live-page-bg.png")}
                alt="Background"
              /> */}
            </div>
          )}
        <div className="playerContainer">
          <div
            className={
              pipMode === true &&
                position > 400 &&
                browserPipMode === false &&
                videoPlaying === true
                ? "Vplayer pipMode"
                : "Vplayer"
            }
          >
            <div className="closeIcon" onClick={() => setPipMode(false)}>
              <Closeicon />
            </div>

            {VideoPlayer && VideoPlayer}
          </div>
        </div>
        <div className="detailSection">
          <div className="TitleSection">
            <h1 className="showTitle">{showDetails?.show_name}</h1>
            <h1 className="episodeTitle">
              {" "}
              {VideoName}
              {/* {VideoName?.length > 15 && "..."}{" "} */}
            </h1>
          </div>
          <div className="description">
            <p>{videoDetails?.synopsis}</p>
          </div>
          <div className="buttonSection">
            {/* <button
              className="moreInfo"
              onClick={() =>
                navigate({
                  pathname: "/show",
                  search: encodeURI(`show_id=${ShowID}`),
                })
              }
            >
              More Info
            </button> */}
            {toMyList === 1 ? (
              <button
                className="AddToList"
                style={{ cursor: "pointer" }}
                onClick={removeFromMyList}
              >
                <span>Remove from List</span>
                <img
                  src={require("../../assets/images/close-white.png")}
                  alt="CrossIcon"
                />
              </button>
            ) : (
              <button
                className="AddToList"
                style={{ cursor: "pointer" }}
                onClick={addToMyList}
              >
                <span>Add to List</span>
                <img src={require("../../assets/images/plus.png")} alt="" />
              </button>
            )}

            <button
              className="Share"
              ref={shareIconRef}
              onClick={() => setShareButton(!ShareButton)}
            >
              Share
              <img
                src={require("../../assets/images/share_icon.svg").default}
                alt=""
              />
              <div
                ref={shareDivRef}
                className={
                  ShareButton
                    ? "shareContainer active"
                    : "shareContainer no-active"
                }
              >
                <div className="facebook">
                  <FacebookShareButton
                    url={"https://kicctv.tv/player?video_ID=" + searchParams.get("video_ID")}
                  >
                    <FacebookIcon size={30} round={false} />
                  </FacebookShareButton>
                </div>
                <div className="twitter">
                  <TwitterShareButton
                    url={"https://kicctv.tv/player?video_ID=" + searchParams.get("video_ID")}
                  >
                    <TwitterIcon size={30} round={false} />
                  </TwitterShareButton>
                </div>
                <div className="whatsapp">
                  <WhatsappShareButton
                    url={"https://kicctv.tv/player?video_ID=" + searchParams.get("video_ID")}
                  >
                    <WhatsappIcon size={30} round={false} />
                  </WhatsappShareButton>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className="share">
          {/* {ShareButton && (
            <div className="facebookContainer ">
              <FacebookShareButton
                url={"https://web-revl.poppo.tv/article-info/" + { ShowID }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-facebook"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </FacebookShareButton>
            </div>
          )} */}
        </div>
      </div>
      <div className="episodeSection">
        <h3 className="title">More Episodes</h3>
        <div className={otherEpisodes?.length > 6 ? "slickSection" : "slickSection center"}>
          <Slider {...settings}>
            {otherEpisodes
              ? otherEpisodes?.map((item, key) => (
                <div
                  className="shows"
                  key={key}
                  onClick={() => {
                    setVideoPlayer(null);
                    episodeClick(item.video_id);
                    navigate(`/player?video_ID=${item?.video_id}`, {
                      state: { id: item?.video_id },
                    });
                  }}
                >
                  <div className="imageContainer">
                    <img
                      src={
                        `https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/` +
                        item?.thumbnail_350_200
                      }
                      alt=""
                    />
                  </div>
                  <h4
                    className="title"
                    onClick={() => {
                      navigate(`/player?video_ID=${item?.video_id}`, {
                        state: { id: item?.video_id },
                      });
                      // setplayVideoId(item.video_id);
                    }}
                  >
                    {item?.video_title}
                  </h4>
                </div>
              ))
              : `No Other Episodes`}
          </Slider>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "150px",
      }}
    >
      <Spinner />
    </div>
  );
}

export default Player;
